define(['jquery'], function($) {
  var _nutrition = {
    _init: function() {
      var nutritionContentDiv = document.querySelectorAll('.product-nutrition-info'),
        siteObj,
        url,
        locale,
        currency,
        productId;

      // Check object exists
      if (nutritionContentDiv.length && window.siteObj) {
        siteObj = window.siteObj;

        // Pull from site object
        url = siteObj.siteNonSecureURL;
        locale = siteObj.customerLocale;
        currency = siteObj.currencyType;
        productId = siteObj.productID;

        // Fetch info
        _nutrition._fetchNutritionInformation(url, locale, currency, productId);
      }
    },

    _fetchNutritionInformation: function(url, locale, currency, productId) {
      $.ajax({
        url: url + locale + '/' + currency + '/' + productId + '/nutrition.awesome',
        success: function(result) {
          _nutrition._successCallback(result);
        },
        error: function(result) {
          _nutrition._failureCallback();
        }
      });
    },

    _successCallback: function(result) {
      if (result !== undefined && result.length > 0) {
        var nutritionTab = document.querySelectorAll('.product-nutrition-info')[0];
        if (nutritionTab && nutritionTab.hasOwnProperty('innerHTML')) {
          nutritionTab.innerHTML = result;
        }
      } else {
        _nutrition._failureCallback();
      }
    },

    _failureCallback: function() {
      var oldNutritionContentDiv = document.querySelectorAll('.old-product-nutrition-info');
      if (oldNutritionContentDiv.length) {
        oldNutritionContentDiv[0].style.display = 'block';
      }
    }
  };

  _nutrition._init();

  return _nutrition;
});
